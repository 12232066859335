import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './lang'
import '@/styles/index.less' // global css
import '@/icons' // icon

import '@/utils/axios' // axios
import { isMobile } from '@/utils'
import * as filters from '@/filters' // global filters
import Footer from '@/components/Footer'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import {
  Picker,
  Field,
  Popup,
  Button,
  Swipe,
  SwipeItem,
  Lazyload,
  Image as VanImage,
  DatetimePicker,
  Col,
  Row,
  Stepper,
  Cell,
  CellGroup,
  Rate,
  Icon,
  Divider,
  Checkbox,
  Collapse,
  CollapseItem,
  RadioGroup,
  Radio,
  CountDown,
  Uploader,
  Toast,
  ActionSheet,
  Calendar,
  Overlay,
  Empty
} from 'vant'
Vue.component('Footer', Footer)
Vue.use(Picker)
Vue.use(Field)
Vue.use(Popup)
Vue.use(Button)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Lazyload)
Vue.use(VanImage)
Vue.use(DatetimePicker)
Vue.use(Col)
Vue.use(Row)
Vue.use(Stepper)
Vue.use(Cell)
Vue.use(CellGroup)
Vue.use(Rate)
Vue.use(Icon)
Vue.use(Divider)
Vue.use(Checkbox)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(CountDown)
Vue.use(Uploader)
Vue.use(Toast)
Vue.use(ActionSheet)
Vue.use(Calendar)
Vue.use(Overlay)
Vue.use(Empty)

Vue.use(ElementUI, { size: 'small', zIndex: 3000, i18n: (key, value) => i18n.t(key, value) })
// register global utility filters
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

// event hub
Vue.prototype.$eventHub = Vue.prototype.$eventHub || new Vue()
Vue.config.productionTip = false
Vue.config.errorHandler = function (err, vm, info) {
  // handle error
  // `info` 是 Vue 特定的错误信息，比如错误所在的生命周期钩子
  // 只在 2.2.0+ 可用
  console.error(err)
  console.log(vm)
  console.log(info)

  if (vm && vm.$root) {
    if (typeof vm.$root.hideLoading === 'function') {
      vm.$root.hideLoading()
    }
  }
}

new Vue({
  router,
  store,
  i18n,
  data () {
    return {
      loading: null
    }
  },
  beforeCreate () {
    if (isMobile(navigator.userAgent || navigator.vendor || window.opera)) {
      window.location = window.location.host.indexOf('bjome.com.cn') >= 0 ? `${window.location.protocol}//appbooking.bjome.com.cn` : 'https://app.no-pms.com'
    }
  },
  methods: {
    showLoading () {
      this.loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
    },
    hideLoading () {
      if (this.loading && typeof this.loading.close === 'function') {
        this.loading.close()
      }
    }
  },
  render: h => h(App)
}).$mount('#app')
