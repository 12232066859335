import Vue from 'vue'
import VueRouter from 'vue-router'
import { getToken } from '@/utils/auth'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home.vue')
  },
  {
    path: '/search',
    name: 'Search',
    component: () => import('@/views/Search.vue')
  },
  {
    path: '/hotel-list',
    name: 'HotelList',
    component: () => import('@/views/HotelList.vue')
  },
  {
    path: '/hotel-detail/hotel-id/:hotelId/arrival/:arrival/departure/:departure/guests/:guests',
    name: 'HotelDetail',
    component: () => import('@/views/HotelDetail.vue')
  },
  {
    path: '/create-reservation/hotel-id/:hotelId/room-type-id/:roomTypeId/arrival/:arrival/departure/:departure/guests/:guests',
    name: 'Reservation',
    component: () => import('@/views/Reservation.vue')
  },
  {
    path: '/payment/reservation-id/:reservationId',
    name: 'Payment',
    component: () => import('@/views/Payment.vue')
  },
  {
    path: '/public-pay/reservation-id/:reservationId',
    name: 'PublicPay',
    component: () => import('@/views/PublicPay.vue')
  },
  {
    path: '/booking-success',
    name: 'BookingSuccess',
    component: () => import('@/views/BookingSuccess.vue')
  },
  {
    path: '/reservation-list',
    name: 'ReservationList',
    component: () => import('@/views/ReservationList.vue')
  },
  {
    path: '/reservation-detail/reservation-id/:reservationId',
    name: 'ReservationDetail',
    component: () => import('@/views/ReservationDetail.vue')
  },
  {
    path: '/invoice/reservation-id/:reservationId',
    name: 'Invoice',
    component: () => import('@/views/Invoice.vue')
  }
]

const router = new VueRouter({
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  if (to.name !== 'Home' && !getToken()) next({ name: 'Home' })
  else next()
})

export default router
